import React from "react";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";


import "./contact.css";
import ModalComponent from "../Modal/modal";

import ContactUsBackground from "../LandingPage/images/ContactUsBackground.png";
import NewYork from "../LandingPage/images/NewYork.png";
import TelAviv from "../LandingPage/images/TelAviv.png";
import Joburg from "../LandingPage/images/Joburg.png";

export function ContactForm() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setUserEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setCompanyNumber] = useState("");
  const [servicesRequested, setServicesRequested] = useState([]);
  const [comments, setComments] = useState("");
  const [website, setWebsite] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleCheckboxChange = (event) => {
    const serviceName = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setServicesRequested((prevServices) => [...prevServices, serviceName]);
    } else {
      setServicesRequested((prevServices) =>
        prevServices.filter((service) => service !== serviceName)
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      name,
      lastName,
      email,
      companyName,
      phoneNumber,
      servicesRequested: servicesRequested.join(", "),
      comments,
      website,
    };
    console.log("Form Data:", formData);

    emailjs
      .send(
        "service_aig2lgu",
        "template_ydcnuty",
        formData,
        "HChwDs3YiahsB2tRd"
      )
      .then((result) => {
        console.log("Email sent successfully:", result.text);

        setName("");
        setLastName("");
        setUserEmail("");
        setCompanyName("");
        setCompanyNumber("");
        setWebsite("");
        setServicesRequested([]);
        setComments("");
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setShowSuccessMessage(false);
      });
  };

  const location = useLocation();
  const isContactPage = location.pathname === "/contact-us";
  const [contactSize, setContactSize] = useState(window.innerWidth > 1350);


  useEffect(() => {
    const handleResize = () => {
      setContactSize(window.innerWidth > 1350);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <form
        className="contactForm"
        style={{
          ...(isContactPage &&
            contactSize && {
              position: "absolute",
              top: "200px",
              right: "100px",
              width: "50%",
            }),
        }}
        onSubmit={handleSubmit}
      >
        <h2 style={{ marginBottom: "25px" }}>Get Started</h2>

        <div className="row">
          <div className="input_group">
            <input
              type="text"
              id="firstName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="firstName">First Name</label>
          </div>
          <div className="input_group">
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <label htmlFor="lastName">Last Name</label>
          </div>
        </div>

        <div className="row">
          <div className="input_group">
            <input
              type="email"
              id="userEmail"
              value={email}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
            <label htmlFor="userEmail">Email</label>
          </div>
          <div className="input_group">
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
            <label htmlFor="companyName">Company Name</label>
          </div>
        </div>
        <div className="row">
          <div className="input_group">
            <input
              type="tel"
              id="companyNumber"
              value={phoneNumber}
              onChange={(e) => setCompanyNumber(e.target.value)}
              required
            />
            <label htmlFor="companyNumber">Phone Number</label>
          </div>
          <div className="input_group">
            <input
              type="text"
              id="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              required
            />
            <label htmlFor="website">Website</label>
          </div>
        </div>
        <h2 style={{ marginBottom: "15px" }}>Services Requested</h2>

        <div
          className="checkbox_container"
          style={{
            marginBottom: "25px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="serviceCheckbox">
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Graphic Design and Brand Creative Process"
                checked={servicesRequested.includes(
                  "Graphic Design and Brand Creative Process"
                )}
                onChange={handleCheckboxChange}
              />
              Graphic Design and Brand Creative Process
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Outsourced Staffing Solutions"
                checked={servicesRequested.includes(
                  "Outsourced Staffing Solutions"
                )}
                onChange={handleCheckboxChange}
              />
              Outsourced Staffing Solutions
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Visual Storytelling"
                checked={servicesRequested.includes("Visual Storytelling")}
                onChange={handleCheckboxChange}
              />
              Visual Storytelling
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Branding Tools and Resources"
                checked={servicesRequested.includes(
                  "Branding Tools and Resources"
                )}
                onChange={handleCheckboxChange}
              />
              Branding Tools and Resources
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Print Design"
                checked={servicesRequested.includes("Print Design")}
                onChange={handleCheckboxChange}
              />
              Print Design
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Digital Consulting"
                checked={servicesRequested.includes("Digital Consulting")}
                onChange={handleCheckboxChange}
              />
              Digital Consulting
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="User Experience Design"
                checked={servicesRequested.includes("User Experience Design")}
                onChange={handleCheckboxChange}
              />
              User Experience Design
            </label>
          </div>

          <div className="serviceCheckbox">
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Workflow Enhancment"
                checked={servicesRequested.includes("Workflow Enhancment")}
                onChange={handleCheckboxChange}
              />
              Workflow Enhancment
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Marketing Strategies"
                checked={servicesRequested.includes("Marketing Strategies")}
                onChange={handleCheckboxChange}
              />
              Marketing Strategies
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Technology Consultation"
                checked={servicesRequested.includes("Technology Consultation")}
                onChange={handleCheckboxChange}
              />
              Technology Consultation
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Brand Promotion"
                checked={servicesRequested.includes("Brand Promotion")}
                onChange={handleCheckboxChange}
              />
              Brand Promotion
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Digital Strategy Development"
                checked={servicesRequested.includes(
                  "Digital Strategy Development"
                )}
                onChange={handleCheckboxChange}
              />
              Digital Strategy Development
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Social Media Marketing"
                checked={servicesRequested.includes("Social Media Marketing")}
                onChange={handleCheckboxChange}
              />
              Social Media Marketing
            </label>
            <label>
              <input
                type="checkbox"
                name="servicesRequested"
                value="Change Management"
                checked={servicesRequested.includes("Change Management")}
                onChange={handleCheckboxChange}
              />
              Change Management
            </label>
          </div>
        </div>

        <div className="input_group">
          <textarea
            name="comments"
            id="comments"
            rows="5"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            required
          ></textarea>
          <label htmlFor="comments">Project related comments</label>
        </div>

        {showSuccessMessage && (
          <h4
            style={{
              color: "green",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "-20px",
              marginBottom: "10px",
            }}
          >
            Form submitted successfully. We will be in contact with you soon.
            Thank you for choosing Omniado!
          </h4>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button style={{color: "black"}} id="submit" className="submit-btn">
            Get Started
          </button>
        </div>
      </form>
    </div>
  );
}

export default function ContactPage() {
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <Helmet>
        <title>Contact | Omniado</title>
        <meta name="description" content="Contact Page"/>
        <link rel="canonical" href="/contact-us" />
    </Helmet>
      <div className="contact-container">
        <img className="contactBackground" src={ContactUsBackground} alt="" />
        <div className="contact_text">
          <div className="contact_text_container">
            <h1>
              <span
                style={{
                  textDecoration: "underline var(--blue)",
                  fontWeight: "400",
                }}
              >
                Connect
              </span>{" "}
              With Us!
            </h1>

            <p>
              Reach out and get in touch with us today to set up a free
              consotation with one of our experts. No matter where you are in
              the world, Omniado is here to help you!
            </p>
          </div>
        </div>
        <div className="form_container_page">
          <ContactForm />
        </div>
        <div className="mobile_form_page">
          <button className="container_button" onClick={() => openModal()}>
            <span
              style={{ marginTop: "0",  padding: "20px 50px", color: "white"}}
              className="btn-get-started"
              onClick={() => openModal()}
            >
              Contact Form
            </span>
          </button>
        </div>
      </div>
      <div className="page_break" style={{ marginTop: "-5px" }}></div>
      <div className="company_locations">
        <div className="company_text">
          <h1>
            Call <span>(347)-467-1047</span>, email to{" "}
            <span>inquiries@omniado.co</span> or complete the form above!
          </h1>
          <p id="company_main_paragraph">
            Ready to unleash the full potential of your brand? Take the first
            step towards success by partnering with Omniado. Our team of experts
            is eager to bring your brand's vision to life, amplifying its impact
            and driving meaningful connections with your audience. Whether
            you're looking to develop a captivating website, engage your
            audience with stunning visuals, create targeted marketing
            strategies, or embark on a transformative digital journey, we have
            the expertise and passion to deliver exceptional results
          </p>
          <div className="loaction_map">
            <img src={Joburg} alt="Joburg" />
            {/* <img src={NewYork} alt="New York" /> */}
            <img src={TelAviv} alt="Tel Aviv" />
          </div>
          <p>
            We are a international company! Find our offices in <span>South africa</span>, <span>Israel</span>.
          </p>
        </div>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}
