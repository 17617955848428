import developBackground from '../LandingPage/images/Develop.jpg'
// import omTalentWebiste from '../LandingPage/images/omTalentWebsite.png'
import engageBackground from '../LandingPage/images/Engage4.mp4'
import marketBackground from '../LandingPage/images/Market.jpg'
import transfomBackground from '../LandingPage/images/Transform3.png'


const servicesData = [
  {
    slug: "develop",
    title: "Develop",
    seoTitle: "Develop Your Business | Omniado",
    seoDescription: "develop page",
    landingImg: developBackground,

    headline: (<>Develop Your Company With <span>Omniado</span></>),
    services: "Web Development | Website Management | Web Based Systems | E-Commerce Solutions | Content Management Systems (Wordpress) | Search Engine Optimization",
    img: "",
    paragraph1: "At Omniado we develop visually pleasing websites and web based systems that reflects your brand's identity.",
    paragraph2: "We place a big emphasis on user experience, ensuring that anything we build is easy to navigate, visually appealing, and optimized for performance. Additionally, we provide ongoing management services to keep your site up-to-date, secure, and functioning smoothly.",
    paragraph3: "Whether you need a one page landing page, an e-commerce solution to sell products online or a content management system to efficiently manage and publish content, we tailor our offering to your unique requirements, enhancing your brand's digital capabilities.",
  },
  {
    slug: "engage",
    title: "Engage",
    seoTitle: "Engage Your Business | Omniado",
    seoDescription: "engage page",
    landingImg: "",
    landingVideo: engageBackground,

    headline: (<>Engage Your Company With <span>Omniado</span></>),
    services: "Graphic Design | Brand Creation | Corporate Identity Design | Visual Storytelling | Print Design | User Experience Design",
    img: "",
    paragraph1: "At Omniado, our talented graphic designers bring your brand to life through engaging and captivating visuals. We work closely with you to understand your brand's personality, values, and target audience, ensuring that our designs effectively communicate your brand's essence. From logos and visual identities to marketing collateral and packaging designs, we create visually appealing assets that engage your audience and leave a lasting impression.",
    paragraph2: "We believe in the power of storytelling to engage and connect with your audience. Through visual elements such as illustrations, infographics, print materials and videos, we help you communicate your brand's story, values, and unique selling propositions. Our goal is to create authentic narratives that resonate with your audience and evoke emotional responses.",
},
  {
    slug: "market",
    title: "Market ",
    seoTitle: "Market Your Business | Omniado",
    seoDescription: "market page",
    landingImg: marketBackground,

    headline: (<>Market Your Company With <span>Omniado</span></>),
    services: "Marketing Strategies | Brand Promotion | Social Media Marketing | Outsourced Staffing Solutions | Branding Tools | Marketing Resources",
    img: "",
    paragraph2: 'From our strategy we then recommend other services, such as, brand promotion, social media marketing and other services to best market your business. ',
    paragraph1: "At Omniado we create tailored marketing strategies that align with your brand's objectives and target audience. Our team conducts market research, competitor analysis, and customer profiling to create comprehensive marketing plans. By identifying the most effective channels and tactics, we ensure that your brand reaches the right people at the right time, maximizing your marketing ROI.",
    paragraph3: "We also offer outsourced staffing solutions through our subsidiary, OmTalent, where we provide skilled South African professionals who seamlessly integrate into your team. Whether you need expertise in customer service, content creation, social media management, SEO, or digital advertising, we provide the talent and support to ensure your marketing operations run smoothly.",
    paragraph4: '“Strategy is about setting yourself apart from the competition. It’s not a matter of being better at what you do – it’s a matter of being different at what you do.”',
    website: "",
  },
  {
    slug: "transform",
    title: "Transform",
    seoTitle: "Transform Your Business | Omniado",
    seoDescription: "transform page",
    landingImg: transfomBackground,

    services: "System Consulting | Strategy Consulting | Workflow Enhancement | Online Strategy Enhancement | Change Management | Employee Culture Training",
    headline: (<>Transform Your Company With <span>Omniado</span></>),
    img: "omTalentWebiste",
    paragraph1: "At Omniado we develop visually pleasing websites and web based systems that reflects your brand's identity.",
    paragraph2: "We place a big emphasis on user experience, ensuring that anything we build is easy to navigate, visually appealing, and optimized for performance. Additionally, we provide ongoing management services to keep your site up-to-date, secure, and functioning smoothly.",
    paragraph3: "Whether you need a one page landing page, an e-commerce solution to sell products online or a content management system to efficiently manage and publish content, we tailor our offering to your unique requirements, enhancing your brand's digital capabilities.",
  },
];

export default servicesData;
