import React from "react";
import "./servicePage.css";
import { useParams } from "react-router-dom";
import servicesData from "./servicesData"; // Import the service data
import { Helmet } from "react-helmet-async";

// import water from "../LandingPage/images/PortfolioWatermark.png";
import orangeBackground from "../LandingPage/images/DevelopOrange.png";
import OrangeInfinity from "../LandingPage/images/BigInfinity.png";

import ModalComponent from "../Modal/modal";

function DynamicServicePage() {
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { slug } = useParams();

  // Find the service data based on the slug
  const service = servicesData.find((service) => service.slug === slug);

  if (!service) {
    return (
      <div style={{ height: "100vh", fontSize: "5rem" }}>Service not found</div>
    );
  }

  let aspectRatio;

  if(slug === "engage"){
    aspectRatio = "14.2/6";
  }else if(slug === "develop"){
    aspectRatio = "16/6";
  }else if(slug === "market"){
    aspectRatio = "16/6";
  }else if(slug === "transform"){
    aspectRatio = "16/9";
  }

  return (
    <>
      <Helmet>
        <title>{service.seoTitle}</title>
        <meta name="description" content={`${service.seoDescription}`} />
        <link rel="canonical" href={`${service.slug}`} />
      </Helmet>
      <div className="service-page">
        <div
          id="service_video_container"
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: "-1",
            aspectRatio
          }}
        >
          {service.landingImg && (
            <img
              src={service.landingImg}
              style={{ width: "100%", height: "auto", zIndex: "3" }}
              alt={service.title}
              preload="auto"
            />
          )}

          {service.landingVideo && (
            <video autoPlay muted loop playsInline controls={false} preload="auto" id="engage_video">
              <source src={service.landingVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <img
            style={{
              height: "99.3%",
              width: "45%",
              position: "absolute",
              zIndex: "4",
              left: "0",
            }}
            src={orangeBackground}
            alt=""
          />
        </div>

        <div className="service-landing">
          <h1>{service.title}</h1>
          <button
            style={{ backgroundColor: "var(--blue)", color: "white" }}
            className="btn-get-started"
            onClick={() => openModal()}
          >
            Get Started
          </button>
        </div>

        <div
          className="page_break"
          style={{ marginTop: "-5px", zIndex: "6" }}
          id="service_page_beak"
        ></div>

        <div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <h1 id="headline">{service.headline}</h1>
          </div>

          <div className="service_breakdown">
            <div className="service_text">
              <h2
                style={{
                  marginBottom: "5px",
                  fontWeight: "400",
                  fontSize: "2rem",
                  textDecoration: "underline #5fc6b3",
                }}
              >
                We Offer:
              </h2>
              <h2 className="our_services">{service.services}</h2>

              <div className="service_paragraphs">
                <div className="service_text_p">
                  <div className="paragraph_container">
                    <p>{service.paragraph1}</p>
                  </div>
                  <div className="paragraph_container">
                    <p>{service.paragraph2}</p>
                  </div>
                </div>

                <img
                  id="OrangeInfinity"
                  src={OrangeInfinity}
                  alt="Omniado Infinity"
                />

                <div className="service_text_p">
                  {service.paragraph3 && (
                    <div className="paragraph_container">
                      <p>{service.paragraph3}</p>
                    </div>
                  )}

                  {service.paragraph4 && (
                    <div className="paragraph_container">
                      <p>{service.paragraph4}</p>
                    </div>
                  )}
                </div>
              </div>

              <button
                style={{ backgroundColor: "var(--orange)", color: "white" }}
                className="btn-get-started"
                onClick={() => openModal()}
              >
                Get Started
              </button>

              <h2
                style={{
                  marginTop: "30px",
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                Learn more or{" "}
                <a style={{ color: "black" }} href="/contact-us">
                  contact us
                </a>{" "}
                to get started today
              </h2>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

export default DynamicServicePage;
