import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom"
import { useEffect } from "react";


import NavBar from './components/Navbar/navbar.jsx';
import LandingPage from './components/LandingPage/landingPage.jsx';
import AboutPage from './components/AboutPage/aboutPage.jsx'
import Portfolio from './components/PortfolioPage/portfolio.jsx'
import FAQ from './components/FAQpage/faq.jsx';
import ContactPage from './components/ContactPage/contact';
import DynamicServicePage from './components/dynamicServicePage/servicePage';
import Accessability from './components/Accessability/accessability';
import Terms from './components/Terms/terms';
import Privacy from './components/Privacy/privacy';
import Footer from './components/Footer/footer.jsx';
import NotFound from './components/NotFound/notFound';



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <NavBar/>
        </header>
        <div id= "main_body">
            <Routes>
              <Route path="/" element={<LandingPage />}/> 
              <Route path="/about-us" element={<AboutPage />}/> 
              <Route path="/portfolio" element={<Portfolio />}/> 
              <Route path="/faqs" element={<FAQ />}/> 
              <Route path="/contact-us" element={<ContactPage/>} />
              <Route path="/our-services/:slug" element={<DynamicServicePage/>} />
              <Route path="/privacy-policy" element={<Privacy/>} />
              <Route path="/terms-of-service" element={<Terms/>} />
              <Route path="/accessability" element={<Accessability/>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
        <footer>
            <Footer/>
            <ScrollToTop/>
        </footer> 
      </Router>
    </div>
  );
}

export default App;
