import React from "react";
import "./client.css";

import BlueWave from "../images/WaveBlue.png";
import ClientsLogos from "../images/clients.png";

import signCity from "../images/SignCityLogoNew.png";
import vstPos from "../images/VSTposlogo.png";
import ShaareiMizrah from "../images/ShaareiMizrahLogo.jpg"


function ClientSection() {
  return (
    <>
      <div className="page_break"></div>

      <div className="clients_section">
        <div className="talk_about_us">
          <h1>Clients Talk About Us</h1>

          <div className="client_reviews">
            <div className="rate">
              <h2>Sign City</h2>
              <p>
                “We’ve been trading for over 30 years and were in need of a
                brand makeover, to help position ourselves as a leader in our
                industry. The Omniado team was incredible in assisting us with
                this! From designing us a beautiful new logo, to developing our
                company profile, we now have an exceptional brand identity that
                aligns with our exceptional service” - Alex
              </p>
              <div className="client_links">
                <img src={signCity} alt="Sign City" />
                <a target="_blank" href="https://sign-city.co.za/" rel="noreferrer">                     
                  Sign City Website
                </a>
              </div>
            </div>
            <div className="rate">
              <h2>VST POS</h2>
              <p>
                “Omniado completely transformed and optimized our operations. We
                are a SaaS technology company operating out of Texas and we
                approached Omniado to build us a backend system to handle our
                orders and sales rep management processes. The system works
                seamlessly and we have saved hundreds of hours of admin. Highly
                recommended” - Joseph
              </p>
              <div className="client_links">
                <img style={{width: "160px"}} src={vstPos} alt="VST POS" />
                <a target="_blank" href="https://vstpos.com/" rel="noreferrer">
                  VST POS website
                </a>
              </div>
            </div>
            <div className="rate">
              <h2>Shaarei Mizrah</h2>
              <p>
                “We couldn’t have imagined a more beautiful logo that represents
                who we are as an organization. Great job guys!” - Alex
              </p>
              <div className="client_links">
                <img src={ShaareiMizrah} alt="Shaarei Mizrah" />
    
              </div>
            </div>
          </div>
        </div>
      </div>

      <img id="blueWave" src={BlueWave} alt="green wave" />

      <div className="client_logos">
        <img id="clientLogos" src={ClientsLogos} alt="Clients" />
      </div>
    </>
  );
}

export default ClientSection;
