import React from "react";
import "./aboutPage.css";
import { Helmet } from "react-helmet-async";

import waves from "../LandingPage/images/waves.png";
import clients2 from "../LandingPage/images/clients2.png";
import aboutBubbles from "../LandingPage/images/AboutUsBubbles.png";
import aboutOmniado from "../LandingPage/images/AboutOmniado.jpg";
import omnaidoLeadership from "../LandingPage/images/OmniadoLeadership.png";

import whatAreWe from "../LandingPage/images/whatAreWe.png";
import ourStory from "../LandingPage/images/ourStory.png";
import buildYourBrand from "../LandingPage/images/buildYourBrand.png";

import ModalComponent from "../Modal/modal";
import { Link } from "react-router-dom";

function AboutLanding() {
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="aboutLanding" style={{aspectRatio: "16/8"}}>
        <img src={aboutOmniado} alt="aboutOmniado" preload="auto"/>
        <h1>About Omniado</h1>
        <button
          style={{ backgroundColor: "white" }}
          className="btn-get-started"
          onClick={() => openModal()}
        >
          Get Started
        </button>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

function AboutText() {
  

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  
  document.addEventListener("scroll", function () {
    const hiddenElements = document.querySelectorAll(".hidden");
    
    hiddenElements.forEach((el) => {
      if (isElementInViewport(el)) {
        el.classList.add("show");
      } else {
        el.classList.remove("show");
      }
    });
  });
 
  
  
  
  

  return (
    <div style={{ marginTop: "-35px" }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100px",
          marginBottom: "-70px",
        }}
        id="wave_container_about"
      >
        <img src={waves} alt="orangewave" id="about_orangewave" />
      </div>

      <div className="aboutBody">
        <div className="aboutbubbles">
          <img src={aboutBubbles} alt="" />
        </div>

        <div className="aboutText">
          <h2>What are we?</h2>
          <p>
            Welcome to Omniado a place where the power of branding is hamessed
            to shape remarkable stories. We firmly believe that a brand is not
            just a collection of visuals and marketing tactics; it is a living
            entity that holds meaning, vokes emotions, and influences
            perceptions. Our passion lies in creating brands that go beyond the
            ordinary, brands that spark curiosity, build trust, and forge
            lasting connections. Let us take you on a journey where your brand
            becomes a captivating narrative, leaving an indelible mark on your
            target audience and propelling your business towards unparalleled
            success.
          </p>
          <br />
          <br />
          <h2>Our Story</h2>
          <p>
            At Omniado, we understand that every brand has a unique story
            waiting to be told. We approach each project with a deep sense of
            purpose and a commitment to uncovering the essence that makes your
            brand extraordinary. Our team of experts invests time and expertise
            into understanding your vision, values, and aspirations. We
            collaborate closely with you to develop a brand strategy that aligns
            with your goals, resonates with your audience, and sets you apart
            from the competition. Together, we embark on a transformative voyage
            where your brand transcends being a mere name and logo, and instead
            becomes a powerful force that captures the hearts and minds of your
            customers.
          </p>
          <br />
          <br />
          <h2>Build Your Brand</h2>
          <p>
            We believe that the brand development process is not just about
            creating a visual identity; it is a holistic journey that
            encompasses the very core of your business. It is about unraveling
            your unique value proposition refining your messaging, and crafting
            an authentic brand experience that loaves a lasting impression. Just
            like the client we worked with who invested three months in
            meticulously crafting their company name and logo, we understand
            that every decision made during the branding process invaluable, it
            is not a waste of time, but rather a vital investment in
            establishing a brand that reflects your identity, resonates with
            your target audience, and speaks volumes about your valuesWe take
            pride in our maticulous attention to detalour unwavering commitment
            to quality, and our ability to breathe life into brands that truly
            mean something to people.
          </p>
        </div>

        <div className="about_text_mobile">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img src={whatAreWe} alt="Omniado What Are We" />

            <p className="hidden">
              Welcome to Omniado a place where the power of branding is hamessed
              to shape remarkable stories. We firmly believe that a brand is not
              just a collection of visuals and marketing tactics; it is a living
              entity that holds meaning, vokes emotions, and influences
              perceptions. Our passion lies in creating brands that go beyond
              the ordinary, brands that spark curiosity, build trust, and forge
              lasting connections. Let us take you on a journey where your brand
              becomes a captivating narrative, leaving an indelible mark on your
              target audience and propelling your business towards unparalleled
              success.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img src={ourStory} alt="Omniado Our Story" />
            <p className="hidden">
              At Omniado, we understand that every brand has a unique story
              waiting to be told. We approach each project with a deep sense of
              purpose and a commitment to uncovering the essence that makes your
              brand extraordinary. Our team of experts invests time and
              expertise into understanding your vision, values, and aspirations.
              We collaborate closely with you to develop a brand strategy that
              aligns with your goals, resonates with your audience, and sets you
              apart from the competition. Together, we embark on a
              transformative voyage where your brand transcends being a mere
              name and logo, and instead becomes a powerful force that captures
              the hearts and minds of your customers.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              paddingBottom: "40px",
            }}
          >
            <img src={buildYourBrand} alt="Build Your Brand Omniado" />

            <p className="hidden">
              We believe that the brand development process is not just about
              creating a visual identity; it is a holistic journey that
              encompasses the very core of your business. It is about unraveling
              your unique value proposition refining your messaging, and
              crafting an authentic brand experience that loaves a lasting
              impression. Just like the client we worked with who invested three
              months in meticulously crafting their company name and logo, we
              understand that every decision made during the branding process
              invaluable, it is not a waste of time, but rather a vital
              investment in establishing a brand that reflects your identity,
              resonates with your target audience, and speaks volumes about your
              valuesWe take pride in our maticulous attention to detalour
              unwavering commitment to quality, and our ability to breathe life
              into brands that truly mean something to people.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AboutPage() {
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>About | Omniado</title>
        <meta name="description" content="about page" />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <AboutLanding />
      <AboutText />
      <div id="fourImages">
        <img src={omnaidoLeadership} alt="omnaidoLeadership" style={{width: '100%', height: 'auto'}} />
      </div>
      <div id="leadershipTeam">
        <h1>Our leadership team</h1>
        <p>
          Through our business units and our dedicated team of solution
          architects, software developers, Ul designers, web designers, digital
          marketers, graphic designers and digital experts, we are here to
          revolutionize the way businesses work.
        </p>
      </div>
      <div className="about_page_clients">
        <img src={clients2} alt="Our clients" />
      </div>
      <div id="ourClients">
        <h1>Our clients</h1>
        <p>
          We have a diverse portfolio of clients based in many different
          countries and industries. Our clients are located all over the world
          in areas such as South Africa, Israel, United States, UK, and Dubai
          and are continuously growing!
        </p>
      </div>
      <div id="startToday">
        <h1>
          Start <span>Today!</span>
        </h1>
        <p>
          Join us at Omniado as we embark on an exhilarating journey of brand
          discovery, innovation, and transformation. Together, let's craft a
          brand that not only captivates and engages but also leaves an enduring
          legacy in the hearts and minds of your audience. Experience the power
          of branding with us, and unlock the true potential of your business
        </p>
        <div id="about_page_btn">
          <Link to="/contact-us">
            <button id="btn-about-contact" className="btn-get-started">
              Contact Us
            </button>
          </Link>
          <button className="btn-get-started" onClick={() => openModal()}>
            Get Started
          </button>
        </div>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

export default AboutPage;
