import React from "react";
import "./portfolio.css";
import { Helmet } from "react-helmet-async";

import clients2 from "../LandingPage/images/clients2.png"
import orangeWave from "../LandingPage/images/waves.png"
import portfolioLanding from "../LandingPage/images/PortfolioOmniado.jpg"
import blueInfinite from "../LandingPage/images/InfinityBlue.png"

import ModalComponent from "../Modal/modal";

function Portfolio(){

    const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    return(
        <>
        <Helmet>
            <title>Portfolio | Omniado</title>
            <meta name="description" content="Portfolio Page"/>
            <link rel="canonical" href="/portfolio" />
        </Helmet>
            <div className="portfolioLanding">
                <img style={{width: "100%"}} src={portfolioLanding} alt="Omnido Portfolio" preload="auto"/>
                <h1>Our Portfolio</h1>
                <h4>Our work <span>speaks for itself</span></h4>
                <button style={{backgroundColor: "white"}}className="btn-get-started" onClick={() => openModal()}>Get Started</button>
            </div>
            <div className="portfolio_orange_wave" >
                <img src={orangeWave} alt="orangewave"/>
            </div>
            <div className="ourPortfolio">
                <div className="ourPortfolio_text">
                    <h1 id="portfolio_text_title" >Our Portfolio</h1>
                    <p style={{fontSize: "1.6rem"}}>We tackle projects from start to finish and have an in-house team of server administrators and cloud experts, graphic designers, and more!</p>
                    <br />
                    <p style={{fontSize: "1.6rem"}}>Once your site is live, don't worry, we have a number of ongoing website maintenance and management options available to ensure you're always supported.</p>
                    
                    <button id="portfolioGetStarted" style={{backgroundColor: "white", marginTop: "30px", color: "var(--blue)"}}className="btn-get-started" onClick={() => openModal()}>Get Started</button>
                </div>
                <div style={{width: '35%'}} id="portfolio_watermark">
                    <img style={{width: "390px", height: "110px", marginLeft: "-120px"}} src={blueInfinite} alt="Omniado Infinity" />
                </div>
                
            </div>
            <div className = "page_break"></div>

            <div className="about_page_clients" id="portfolio_clients">
                <img src={clients2} alt="Omniado Clients" />
            </div>
            <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />

        </>
    );
}

export default Portfolio;