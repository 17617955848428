import React from "react";
import "./navBar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";

//import svgs
import Email from "./NavIcons/email.svg";
import Phone from "./NavIcons/phone.svg";

import Logo from "./NavIcons/OmniadoLogo.png";
import OmTalent from "./NavIcons/OmTalent.png";

function NavBar() {
  const [showSidebar, setShowSidebar] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [socialmedia, setSocialmedia] = useState(false);

  const toggleMenu = () => {
    if(socialmedia){
      setSocialmedia(false);
    }
    setIsOpen(!isOpen);
    
  };

  const handleClick = (event) => {
    if (!event.target.closest(".menu-button")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <div className={`contactBar ${showSidebar ? "active" : ""}`}>
        <div className="leftNav">
          <div className="phone">
            <img src={Phone} id="phoneimg" alt="phone" />
            <h6>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="tel:+13474671047"
              >
                +1 (347) 467-1047
              </a>
            </h6>
          </div>
          <div className="email">
            <img src={Email} id="emailimg" alt="email" />
            <h6>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="mailto:info@omniado.com"
              >
                info@omnaido.com
              </a>
            </h6>
          </div>
        </div>

        <div className="rightNav">
          <ul className={`social_links ${socialmedia ? "open" : ""}`}>
            <div className="mobile_social_links">
              <li className="omniado_phone">
                <span className="icon">
                  <i className="fa-solid fa-phone"></i>
                </span>
                <a href="tel:+13474671047">+1(347)4671047</a>
              </li>
              <li className="omniado_email">
                <span className="icon">
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <a href="mailto:info@omniado.com">info@omniado.com</a>
              </li>
            </div>
            <li className="facebook">
              <span className="icon">
                <i className="fa-brands fa-facebook-f"></i>
              </span>
              <a href="https://www.facebook.com/OmniadoAgency/" className="text" target="_blank" rel="noreferrer">
                Facebook
              </a>
            </li>

            <li className="linkedin">
              <span className="icon">
                <i className="fa-brands fa-linkedin-in"></i>
              </span>
              <a
                href="https://www.linkedin.com/company/omniado/?originalSubdomain=za"
                target="_blank"
                className="text"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>

            <li className="tiktok">
              <span className="icon">
                <i className="fa-brands fa-tiktok"></i>
              </span>
              <a href="#" className="text" target="_blank" rel="noreferrer">
                TikTok
              </a>
            </li>

            <li className="instagram">
              <span className="icon">
                <i className="fa-brands fa-instagram"></i>
              </span>
              <a href="https://instagram.com/omniadoagency?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer" className="text">
                Instagram
              </a>
            </li>
          </ul>
          <div className="button_container">
            <button
              className={`scial_media_btn ${socialmedia ? "open" : ""}`}
              onClick={() => setSocialmedia(!socialmedia)}
            >
              <div style={{ fontSize: "30px", marginRight: "7px", color: "black" }}>
                <i className="fa-solid fa-share-nodes"></i>
              </div>
              <span
                style={{
                  fontSize: "20px",
                  marginBottom: "5px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                Social Media
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Omnaido" id="mainLogo" />
          </Link>
        </div>

        <div
          className={`menu-icon ${showSidebar ? "active" : ""}`}
          onClick={toggleSidebar}
        >
          <i className="fas fa-bars"></i> {/* Font Awesome bars icon */}
        </div>

        <div className={`links ${showSidebar ? "active" : ""}`}>
          <li>
            <NavLink
              to="/"
              className="nav-link"
              activeclassname="active"
              onClick={() => setShowSidebar(false)}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-us"
              className="nav-link"
              activeclassname="active"
              onClick={() => setShowSidebar(false)}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={toggleMenu}
              activeclassname="active"
              className="menu-button"
            >
              Our Services
              <span className={`caret ${isOpen ? "caret-up" : "caret-down"}`} />
            </NavLink>
          </li>
          {isOpen && (
            <div className={`menu-list ${isOpen ? "open" : ""}`}>
              <Link
                to="/our-services/develop"
                className="menu-item"
                onClick={() => setShowSidebar(false)}
              >
                Develop
              </Link>
              <Link
                to="/our-services/engage"
                className="menu-item"
                onClick={() => setShowSidebar(false)}
              >
                Engage
              </Link>
              <Link
                to="/our-services/market"
                className="menu-item"
                onClick={() => setShowSidebar(false)}
              >
                Market
              </Link>
              <Link
                to="/our-services/transform"
                className="menu-item"
                onClick={() => setShowSidebar(false)}
              >
                Transform
              </Link>
            </div>
          )}
          <li>
            <NavLink
              to="/portfolio"
              className="nav-link"
              activeclassname="active"
              onClick={() => setShowSidebar(false)}
            >
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/faqs"
              className="nav-link"
              activeclassname="active"
              onClick={() => setShowSidebar(false)}
            >
              FAQs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-us"
              className="nav-link"
              activeclassname="active"
              onClick={() => setShowSidebar(false)}
            >
              Contact Us
            </NavLink>
          </li>
        </div>
        <li className={`OmTalent-btn ${showSidebar ? "active" : ""}`}>
          <a target="_blank" href="https://www.getomtalent.com/" rel="noreferrer">
            <img className="omTalent-img" src={OmTalent} alt="OmTalent" />
          </a>
        </li>
      </div>
    </>
  );
}

export default NavBar;
