import React from "react";
import "./terms.css";
import { Helmet } from "react-helmet-async";

function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Omniado</title>
        <meta name="description" content="Omniados Terms of service" />
        <link rel="canonical" href="/terms-of-service" />
      </Helmet>
      <div className="terms_container">
        <div className="terms_text">
          <h1>Terms of Service</h1>

          <h2>Effective Date: July 2021</h2>
          <br />
          <h3>1. Acceptance of Terms</h3>

          <p>
            Welcome to Omniado (Pty) Ltd ("we," "us," or "our"). By accessing or
            using our website www.omniado.com and our services, you agree to
            abide by these Terms of Service. If you do not agree to these terms,
            please do not use our website or services.
          </p>
          <br />
          <h3>2. Use of Website</h3>

          <p>
            You agree to use our website and services only for lawful purposes
            and in compliance with applicable laws and regulations. You shall
            not engage in any activity that disrupts, harms, or interferes with
            the functionality of our website or services.
          </p>
          <br />
          <h3>3. Intellectual Property</h3>

          <p>
            All content on our website, including text, images, graphics, logos,
            and trademarks, is protected by intellectual property laws. You may
            not reproduce, distribute, modify, or create derivative works based
            on our content without our prior written consent.
          </p>
          <br />
          <h3>4. Disclaimer of Warranty</h3>

          <p>
            Our website and services are provided on an "as-is" and "as
            available" basis. We make no warranties, express or implied,
            regarding the accuracy, reliability, or availability of our website
            or services.
          </p>
          <br />
          <h3>5. Limitation of Liability</h3>

          <p>
            We shall not be liable for any direct, indirect, incidental,
            consequential, or punitive damages arising out of or related to your
            use of our website or services.
          </p>
          <br />
          <h3>6. Indemnification</h3>

          <p>
            You agree to indemnify, defend, and hold us harmless from any
            claims, liabilities, damages, costs, and expenses arising from your
            use of our website or services, or your violation of these Terms of
            Service.
          </p>
          <br />
          <h3>7. Changes to Terms</h3>

          <p>
            We may update these Terms of Service from time to time. Any changes
            will be effective upon posting on our website. Your continued use of
            our website or services after changes constitutes acceptance of the
            revised terms.
          </p>
          <br />
          <h3>8. Governing Law</h3>

          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of South Africa. Any disputes shall be
            subject to the exclusive jurisdiction of the courts of South Africa.
          </p>
        </div>
      </div>
    </>
  );
}

export default Terms;
