import React, { useState, useEffect } from "react";
import "./footer.css";

import { Link } from "react-router-dom";

import OmniadoLogo from "../LandingPage/images/Omniado-Logo.png";
import LightOrangeWave from "../LandingPage/images/lightorangewave.png";

function Footer() {
  const [socialView, setSocialView] = useState(window.innerWidth > 1330);

  useEffect(() => {
    const handleFooter = () => {
      setSocialView(window.innerWidth > 1330);
    };

    window.addEventListener("resize", handleFooter);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleFooter);
    };
  }, []);

  return (
    <>
      <img className="light_orange_wave" src={LightOrangeWave} alt="" />

      <div className="main_footer">
        <div className="footer_logo">
          <img src={OmniadoLogo} alt="Omniado Logo" />
        </div>
        <div className="footer_quick_links">
          <h2>Quick Links</h2>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About Omniado</Link>
          </li>
          {/* <li>
            <Link to="/test">Our Services</Link>
          </li> */}
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </div>
        <div className="footer_contact_us">
          <h2>Contact Us</h2>
          <h3>Email:</h3>
          <p>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:info@omniado.com"
            >
              info@omnaido.com
            </a>
          </p>
          <h3>Phone:</h3>
          <p>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="tel:+13474671047"
            >
              +1 (347) 467-1047
            </a>
          </p>

          {socialView && (
            <div style={{ position: "relative", marginBottom: "15px" }}>
              <h3 style={{ marginBottom: "10px", marginTop: "6px" }}>
                Follow Us:
              </h3>

              <ul
                className="social_links"
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <li className="facebook">
                  <span className="icon">
                    <i className="fa-brands fa-facebook-f"></i>
                  </span>
                  <a
                    href="https://www.facebook.com/OmniadoAgency/"
                    className="text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>

                <li className="linkedin">
                  <span className="icon">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </span>
                  <a
                    href="https://www.linkedin.com/company/omniado/?originalSubdomain=za"
                    target="_blank"
                    className="text"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>

                <li className="tiktok">
                  <span className="icon">
                    <i className="fa-brands fa-tiktok"></i>
                  </span>
                  <a href="#" className="text" target="_blank" rel="noreferrer">
                    TikTok
                  </a>
                </li>

                <li className="instagram">
                  <span className="icon">
                    <i className="fa-brands fa-instagram"></i>
                  </span>
                  <a
                    href="https://instagram.com/omniadoagency?igshid=MzRlODBiNWFlZA=="
                    className="text"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="second_footer">
        <div className="second_footer_p">
          ©<p> All Rights Reserved</p>
        </div>
        <div className="second_footer_a">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span id="vertical-line"></span>
          <Link to="/terms-of-service">Terms of Service</Link>
          <span id="vertical-line"></span>
          <Link to="/accessability">Accessability</Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
