import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import "./video.css";
import video from "./OmnaidoTeamUp.mp4";
import MobileVideo from "./OmnaidoTeamUpMobile.mp4";

import ModalComponent from "../../Modal/modal.jsx";


const VideoBackground = () => {
  
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth <= 700;

  const [videoSource, setVideoSource] = useState  (isMobile ? (MobileVideo) : (video));
  
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <div className="blur">
        <div className="brand_needs">
          <h1>Building meaningful, memorable, and innovative</h1>
          <h1>brands together!</h1>
        </div>
        <div className="video_text">
          <h1 id="video_title">Omniado</h1>
          <p>"the way of <span>everything</span>"</p>
        </div>

        <div className="video_buttons"> 
          <Link style={{textDecoration: "none"}} to="/about-us"><button id = "learn_more">Learn More</button></Link>
          <button onClick={() => openModal()}>Get Started</button>
        </div>

        <video autoPlay muted loop playsInline controls={false} preload="auto" className="video-background" style={{width: "100%", height: "auto"}}>
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default VideoBackground;
