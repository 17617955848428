import React from "react";
import "./landingPage.css";
import { Helmet } from "react-helmet-async";


import VideoBackground from "../LandingPage/video/video.jsx";
import OurCompany from './OurCompany/overview.jsx';
import AboutSection from './About/aboutSection.jsx';
import ClientSection from './ClientSection/client.jsx';

import ModalComponent from "../Modal/modal";


function JoinUsToday(){

    const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    return(
        <>
            <div className="page_break"></div>
            <div className="join_us">
                <div className="join_us_text" >
                    <h1>Join us <span>today!</span></h1>
                    <p>At Omniado, we are dedicated to your brand's success. Our team of experts is committed to delivering exceptional results that drive growth, engagement, and transformation. Partner with us and let's create a brand journey that propels your business forward.</p>
                    <button className="btn-get-started" onClick={() => openModal()}>Get Started</button>
                </div>
                
            </div>
            <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />

        </>
    );
}


function LandingPage (){
    return(
        <div>
            <Helmet>
                <title>Home | Omniado</title>
                <meta name="description" content="Omniado"/>
                <link rel="canonical" href="/" />
            </Helmet>

            <VideoBackground/>
            <OurCompany/>
            <AboutSection/>
            <ClientSection/>
            <JoinUsToday/>
        </div>
    );
}

export default LandingPage;