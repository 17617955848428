import React from "react";
import "./accessability.css";
import { Helmet } from "react-helmet-async";



function Accessability() {
  return (
    <>
      <Helmet>
        <title>Accessibility | Omniado</title>
        <meta name="description" content="accessibility page" />
        <link rel="canonical" href="/accessibility" />
      </Helmet>
      <div className="accessability_container">
        <div className="accessability_text">
          <h1>Accessibility Policy</h1>

          <h2>Effective Date: January 2021</h2>
          <br />
          <h3>1. Commitment to Accessibility</h3>

          <p>
            Omniado (Pty) Ltd ("we," "us," or "our") is committed to making our
            website www.omniado.com accessible to all users, including those
            with disabilities.
          </p>
          <br />
          <h3>2. Accessibility Features</h3>

          <p>
            We aim to ensure our website conforms to the Web Content
            Accessibility Guidelines (WCAG) 2.0 Level AA standards. We implement
            various accessibility features and techniques to enhance user
            experience for individuals with disabilities.
          </p>
          <br />
          <h3>3. Feedback and Assistance</h3>

          <p>
            We welcome feedback and suggestions for improving the accessibility
            of our website. If you encounter any accessibility issues or have
            suggestions, please contact us at [contact email address].
          </p>
          <br />
          <h3>4. Ongoing Improvement</h3>

          <p>
            We continuously strive to improve the accessibility of our website.
            This includes regular assessments, audits, and updates to address
            accessibility issues and ensure compliance with accessibility
            standards.
          </p>
          <br />
          <h3>5. Third-Party Content</h3>

          <p>
            While we strive to make our content accessible, some third-party
            content on our website may not be fully accessible. We encourage
            users to notify us of any such content so we can address the issue.
          </p>
          <br />
          <h3>6. Contact Us</h3>

          <p>
            If you have any questions, concerns, or feedback regarding the
            accessibility of our website, please contact us at info@omniado.com
          </p>
        </div>
      </div>
    </>
  );
}

export default Accessability;
