import React from "react";
import "./aboutSection.css";

import { Link } from "react-router-dom";

import Pillars from "../images/Pillars.png";
import Develop from "../images/DevelopIcon.png";
import Engage from "../images/EngageIcon.png";
import Market from "../images/MarketIcon.png";
import Transform from "../images/TransformIcon.png";

import Bubbles from "../images/PillarBubbles.png";

import ModalComponent from "../../Modal/modal";

function AboutSection() {
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Correct way of using useState

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="about_section">
        <div className="page_break"></div>

        <img id="bubbles" src={Bubbles} alt="Our Pillers" />

        <div className="about_section_text">
          <h1 className="about_section_title">About Omniado</h1>
          <hr />
          <div
            style={{
              width: "90%",
              display: "flex",
              margin: "0 auto",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <p>
              At Omniado, we are passionate about helping businesses thrive
              through the power of branding. Our comprehensive services
              encompass four key pillars:
            </p>
            <br />
            <br />
            <p style={{ textAlign: "center" }}>
              Develop, Engage, Market, and Transform
            </p>
            <br />
            <br />
            <p>
              Each pillar represents a vital aspect of building a successful
              brand and achieving your business objectives. With our expertise
              and dedication, we offer a range of services tailored to your
              specific needs and goals.
            </p>
          </div>

          <button
            className="btn-get-started"
            style={{ position: "relative", margin: "40px auto " }}
            onClick={() => openModal()}
          >
            Get Started
          </button>
        </div>

        <div className="about_section_pillers">
          <img id="pillars" src={Pillars} alt="Omniado Pillars" />

          <div className="piller_text">
            <div>
              <h1>
                <img src={Develop} alt="Develop" />
                Develop
              </h1>
              <p>
                <br />
                <br />
                Web Development
                <br />
                Website Management
                <br />
                Web-Based Systems
                <br />
                E-Commerce Solutions
                <br />
                Content Management Systems
                <br />
                Search Engine Optimization
              </p>
            </div>
            <div>
              <h1>
                <img src={Engage} alt="Develop" />
                Engage
              </h1>
              <p>
                <br />
                <br />
                Graphic Design
                <br />
                Brand Creation
                <br />
                Corporate Identity Design
                <br />
                Visual Storytelling
                <br />
                Print Design
                <br />
                User Experience Design
              </p>
            </div>
            <div>
              <h1>
                <img src={Market} alt="Develop" />
                Market
              </h1>
              <p>
                <br />
                <br />
                Marketing Strategies
                <br />
                Brand Promotion
                <br />
                Social Media Marketing
                <br />
                Outsourced Staffing Solutions
                <br />
                Branding Tools
                <br />
                Marketing Resources
              </p>
            </div>
            <div>
              <h1>
                <img src={Transform} alt="Develop" />
                Transform
              </h1>
              <p>
                <br />
                <br />
                System Consulting
                <br />
                Startup Consulting
                <br />
                Workflow Enhancement
                <br />
                Online Strategy Development
                <br />
                Change Management
                <br />
                Employee Culture Training
              </p>
            </div>
          </div>
        </div>

        <Link style={{ textDecoration: "none" }} to="./about-us">
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "80px",
            }}
            className="btn-get-started"
          >
            Learn More
          </button>
        </Link>
      </div>
      <ModalComponent isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

export default AboutSection;
