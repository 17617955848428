import React from 'react';
import './notFound.css'; // Import the CSS for styling

function NotFound() {
  return (
    <div className="not-found">
        <div className="not-found-text">
            <h1>404</h1>
            <p>Oops! The page you're looking for doesn't exist.</p>
        </div>
      
    </div>
  );
}

export default NotFound;
