import { useState, useEffect } from "react";
import React from "react";
import "./faq.css";
import { Helmet } from "react-helmet-async";

import faqBubbles from "../LandingPage/images/FAQBubbles.png";

const FAQItem = ({ question, index, activeIndex, setActiveIndex, }) => {
  const isCurrentQuestionActive = activeIndex === index;
  const [isGrid, setIsGrid] = useState(true);

  useEffect(() => {
    const handleGrid = () => {
      setIsGrid(window.innerWidth > 960);
    };
    window.addEventListener("resize", handleGrid);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleGrid);
    };

  }, []);

  const toggleAnswer = () => {
    if (isCurrentQuestionActive) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="questions" >
      <div className="question_card">
        <div className="question_title_contianer">
          <a onClick={toggleAnswer} style={{ cursor: "pointer" }}>
            <h2>{question.text}</h2>
          </a>
          <button
            onClick={toggleAnswer}
            className={`toggle-button ${
              isCurrentQuestionActive ? "minus" : "plus"
            }`}
          >
            {isCurrentQuestionActive ? "-" : "+"}
          </button>
        </div>

        <div className="answers">
          <p
            className="question_answer"
            style={{
              display: isGrid
                ? isCurrentQuestionActive
                  ? "block"
                  : "none"
                : "flex",
            }}
          >
            {question.answer}
          </p>
        </div>
      </div>
    </div>
  );
};

function FAQ() {

  const contactLink = <a href='/contact-us'>clicking here</a>;

  const faqData = [
    {
      text: "What services does Omniado provide?",
      answer: "Omniado offers a comprehensive range of services that revolve around the core belief in the power of branding. Our service offering is split into four main pillars. Develop, Engage, Market and Transform. This includes brand development, creative processes, strategic marketing, and transformative digital solutions to name but a few. We offer a holistic approach to branding that goes beyond just creating a logo.",
    },
    {
      text: "What sets Omniado apart in the branding space?",
      answer: "What sets Omniado apart is our 15 years of collective experience in the branding industry. This experience has allowed us to refine our service offering and develop a deep understanding of what it takes to create a successful brand. We focus on guiding clients through every stage of the brand journey, ensuring that their brand not only resonates but also captivates their target audience.",
    },
    {
      text: "How does Omniado approach brand development?",
      answer: "At Omniado, brand development is a thoughtful and strategic process. We understand that a brand is more than just a logo; it's a representation of values, identity, and emotions. Our approach involves in-depth research, creative brainstorming, and collaboration with our clients to define the unique essence of their brand.",
    },
    {
      text: "Can you elaborate on Omniado's creative processes?",
      answer: "Our creative processes are designed to bring a brand's personality to life. We work closely with our clients to translate their brand's core values into visual and textual elements that resonate with their audience. This includes designing logos, creating brand guidelines, developing visual identity, and crafting compelling messaging.",
    },
    {
      text: "How does Omniado approach strategic marketing for branding?",
      answer:"Strategic marketing is a crucial aspect of branding. Omniado develops tailored marketing strategies that align with a brand's goals and target audience. This includes identifying the most effective channels, crafting engaging content, and leveraging digital tools to maximize brand visibility and engagement.",
    },
    {
      text: "What are transformative digital solutions in the context of branding?",
      answer: "Transformative digital solutions refer to leveraging technology and digital platforms to enhance a brand's presence and impact. This could involve website development, social media optimization, online advertising, and other digital strategies that help a brand connect with its audience in a meaningful way.",
    },
    {
      text: "How does Omniado ensure a brand makes a lasting impact?",
      answer: "Omniado's approach to branding is focused on creating a lasting impact by ensuring that every aspect of the brand journey is carefully considered and executed. From brand development to creative processes and strategic marketing, we aim to create a cohesive and memorable brand experience that resonates with customers over time.",
    },
    {
      text: "Can you provide examples of brands Omniado has worked with?",
      answer: "Sure! Omniado has worked with a diverse range of brands across various industries. Our experience spans from startups to established businesses, helping each brand find its unique voice and make a lasting impact in its respective market. Reach out and we’ll be glad to show you what we’ve done :)",
    },
    {
      text: "How can I get started with Omniado's branding services?",
      answer: (<span style={{fontSize: "1.3rem"}}>Getting started with Omniado is easy! Simply reach out to us by {contactLink} or contact us via the information provided. We are excited to discuss your branding needs, goals, and how we can tailor our services to meet your specific requirements.</span>),
    },
    {
      text: "What is the ultimate goal of Omniado's branding services?",
      answer: "The ultimate goal of Omniado's branding services is to empower our clients with a brand that resonates, captivates, and creates a lasting impact. We strive to help our clients build strong brand identities that connect with their audience on a deep level, ultimately driving business success and growth.",
    },
    {
      text: "Help, I can't find the answer to my question!",
      answer: (<span>We are so sorry that you cannot find what you are looking for. Please click here to <a href='/contact-us'>contact us</a> and one of our team members will be happy to assist you and answer any further questions you might have.</span>),
    },
  ];

  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <>
      <Helmet>
          <title>FAQs | Omniado</title>
          <meta name="description" content="questions"/>
          <link rel="canonical" href="/faqs" />
      </Helmet>

      <div className="frequent-questions">
        <div id="faqBubbles">
          <img
            style={{ marginLeft: "70px", marginTop: "50px" }}
            src={faqBubbles}
            alt=""
          />
        </div>

        <div className="questions-container">
          <div className="questions-title">
            <h1>
              Frequently Asked <span>Questions</span>
            </h1>
          </div>

          <hr className="question_top_border" />
          <div className="grid_container">
            {faqData.map((question, index) => (
              <FAQItem
                key={index}
                index={index}
                question={{ ...question }}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
