import React from "react";
import "./privacy.css";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Omniado</title>
        <meta name="description" content="Omniados Privacy Policy" />
        <link rel="canonical" href="/privacy-policy" />
      </Helmet>
      <div className="privacy_policy_container">
        <div className="privacy_policy_text">
          <h1>Privacy Policy</h1>

          <h2>Effective Date: January 2021</h2>
          <br />
          <h3>1. Introduction</h3>

          <p>
            Welcome to Omniado (Pty) Ltd ("we," "us," or "our"). We are
            committed to protecting your privacy and ensuring the security of
            your personal information. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your personal information when
            you visit our website www.omniado.com, or use our services. By using
            our website or services, you consent to the practices described in
            this Privacy Policy.
          </p>
          <br />
          <h3>2. Information We Collect</h3>

          <p>
            We may collect personal information that you provide directly to us,
            including but not limited to your name, email address, contact
            information, and any other information you provide while using our
            website or services.
          </p>
          <br />
          <h3>3. How We Use Your Information</h3>

          <h5>
            We may use the personal information we collect for various purposes,
            including:
          </h5>

          <p>
            Providing, maintaining, and improving our website and services.
            Sending you updates, newsletters, and promotional materials related
            to our services. Responding to your inquiries, comments, or
            questions. Analyzing and understanding user behavior to improve user
            experience. Complying with legal obligations.
          </p>
          <br />
          <h3>4. How We Disclose Your Information</h3>

          <h5>We may share your personal information with:</h5>

          <p>
            Service providers and third-party vendors who help us operate and
            manage our website and services. Business partners and affiliates
            for marketing and promotional purposes. Legal authorities, as
            required by law or to protect our rights and safety.
          </p>
          <br />
          <h3>5. Your Choices</h3>

          <h5>You have the right to:</h5>
          <p>
            Opt-out of receiving marketing emails by following the unsubscribe
            instructions provided in the email. Update or correct your personal
            information by contacting us directly. Request access to the
            personal information we hold about you and request its deletion,
            subject to legal requirements.
          </p>
          <br />
          <h3>6. Security</h3>

          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, or alteration.
            However, no method of transmission over the internet or electronic
            storage is completely secure, and we cannot guarantee absolute
            security.
          </p>
          <br />
          <h3>7. Links to Third-Party Websites</h3>

          <p>
            Our website may contain links to third-party websites. We have no
            control over the content, privacy policies, or practices of these
            websites. We recommend reviewing the privacy policies of any
            third-party sites you visit.
          </p>
          <br />
          <h3>8. Changes to this Privacy Policy</h3>

          <p>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for legal reasons. We will notify you of any
            significant changes by posting a prominent notice on our website or
            by sending you an email.
          </p>
          <br />
          <h3>9. Contact Us</h3>
          <p>
            If you have any questions, comments, or concerns about this Privacy
            Policy, please <Link to="/contact-us">contact us</Link> at{" "}
            <a href="mailto:info@omniado.com">info@omniado.com</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Privacy;
